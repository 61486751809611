<template>

    <ul>
        <li v-for=" el in data">
            <faqELement
            :title="el.title"
            :index="el.index"
            :text="el.text"
            >
        </faqELement>
        </li>
    </ul>


</template>

<script>

import faqELement from "./faqELement.vue";

export default{
    props: ["data"],
    components: {faqELement},
}
</script>

<style scoped>
ul{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 6rem 0;
}
@media screen and (max-width: 1000px){
    ul{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 3rem 0;
}
}
</style>