<template>

<header class="lightColorText" :class="{active :  this.scrollDirection}" >

    <h1  :class="{activeMenu : active}" >{{ name }}</h1>
    <nav class="navMax" >
        <ul >
            <li v-for="el in data">
                <a :href="'#' +  el.link">{{el.name}}</a>
            </li>
        </ul>
    </nav>
    <div class="dropdown-container darkColorBck"  @click=" this.selectActive =! this.selectActive" :class="{active: selectActive}">
            <div class="dropdown-toggle click-dropdown" >
                {{ lang }}
            </div>
            <div class="dropdown-menu darkColorBck">
              <ul class="darkColorBck">
                <li @click="selectLang('ru', false)"><a href="#">RUS</a></li>
                <li @click="selectLang('pl', false)"><a href="#">PL</a></li>
                <li @click="selectLang('en', false)"><a href="#">EN</a></li>
              </ul>
            </div>
          </div>
          <div class="hamburger" @click="hamburgerClick" :class="{activeMenu : active}">
                <span></span>
                <span></span>
                <span></span>
          </div>
        <div ref="menu" class="menu" :class="{activeMenu : active}">
            <nav class="navMin" >
                <ul >
                    <li v-for="el in data" @click="hamburgerClick">
                      <a class="darkColorText"  :href="'#' +  el.link"> <p>{{el.name}}</p></a>
                    </li>
                </ul>
            </nav>
            <div class="bottomNav">
                <div class="contactData">
                    <p>
                        {{ contactData.adress }}
                    </p>
                    <p> tel:
                        <a :href="'tel:' + telLink"> {{ contactData.tel }}</a>
                    </p>
                </div>
                <ul class="minLang">
                <li @click="selectLang('ru', true)"><a href="#">RUS</a></li>
                <li @click="selectLang('pl', true)"><a href="#">PL</a></li>
                <li @click="selectLang('en', true)"><a href="#">EN</a></li>
                </ul>
            </div>
        </div>
</header>

</template>



<script>

export default{
    data(){

        return{
            name: 'ALL MADE',
            selectActive: false,
            lang: document.getElementsByTagName("html")[0].getAttribute("lang").split("-")[0],
            active: false,
            telLink: this.contactData.tel.split(" ").join("")
        }
    },
    props: ["scrollDirection", "data", "contactData"],
    mounted(){
        window.addEventListener("scroll", ()=>{
            this.selectActive =false
        })
    },
    methods: {
        selectLang(lang, statment){
            document.documentElement.lang = lang
            this.lang = document.getElementsByTagName("html")[0].getAttribute("lang")
            this.$emit('lang-change')
            if(statment)
                this.hamburgerClick();
        },
        hamburgerClick(){
            this.active =!this.active
            if(this.active)
                document.body.style.overflowY = "hidden"
            else
            document.body.style.overflowY = "auto"

        }

    }


}

</script>

<style scoped>

header{
    background-color: var(--primaryColorDark);
    width: 100%;
    position: fixed;
    top: 0; left: 0;
    padding: 2em 4em;
    z-index: 999;
    transition: 0.3s;    
    transform: translateY(-100%);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
header.active{
    transform: translateY(0);
    
}
h1{
    font-size: 4em;
    font-weight: 300;
    position: relative;
    z-index: 100;
    transition: 0.6s;
}
h1.activeMenu{
color: #303030;
}
.navMax{
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);

}
ul{
    display: flex;
}
a{
    color: inherit;
    font-size: 1.8em;
    padding: 1.5em;
}

.dropdown-container{
    margin-right: 2rem;
    position: relative;
    padding: 0 1rem ;
}
.dropdown-toggle{
    font-size: 1.8rem;
    text-transform: uppercase; 
    user-select: none;
}
.dropdown-container ul{
    display: none;
    position: absolute;
    transform: translateX(-1rem); 
    padding: 1rem 0;
    width: 100%;
    margin-top: 1rem;
    gap: 0.5rem;
}
.dropdown-container ul li{
    width: 100%;
}
.dropdown-container ul a{
    padding: 0;
    background-color: #303030;
    width: 100%;
    display: block;
    padding-left: 1rem;

}
.dropdown-container.active ul{
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}
.menu{
    position: absolute;
    top: 0; right: 0;
    width: 100%;
    height: 100dvh;
    transition: 0.8s;
    transform: translateX(350%);
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding: 0 2rem 2rem 2rem;
}
.menu.activeMenu{
    transform: translateX(100%); 
    transition-delay:  0.05s;
}
.hamburger{
    background-color: white;
    width: 91px;
    height: 100%;
    position: absolute;
    z-index: 99;
    transition: 0.3s;
    transform: translateX(200%); 
    cursor: pointer;
    right: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.hamburger::after{
    content: " ";
    height: 100%;
    width: 0;
    position: absolute;
    left: 0;top: 0;
    background-color: white;
    transition: 0.8s;
    transform: translateX(-100%);
}
.hamburger.activeMenu::after{
    width: 100vw;
}
.hamburger>span{
    width: 80%;
    height: 2px;
    background-color: #303030;
    border-radius: 4px;
    transition: 0.3s;
}
.hamburger.activeMenu>span{
    position: absolute;
}
.hamburger.activeMenu>span:nth-child(1){
    transform: translate(-50%, -50%) rotate(45deg);
    top: 50%; left: 50%;
}
.hamburger.activeMenu>span:nth-child(2){
    opacity: 0;
}
.hamburger.activeMenu>span:nth-child(3){
    transform: translate(-50%, -50%) rotate(-45deg);
    top: 50%; left: 50%;
}
.navMin, .bottomNav{
    margin-top: auto;
}
.minLang{
    margin-top: auto;
}
.minLang a {
    color: #303030;
    font-size: 1.6rem;
    font-weight: 200;
    padding: 1rem 4rem;
    border:1px solid #303030 ;
    position: relative;
}
.minLang a::after{
    content: "";
    width: 100%;
    height: 0;
    position: absolute;
    bottom: 0;left: 0;
    background-color:#303030;
    transition: 0.3s;
    z-index: -1;
}
.minLang a:hover{
    color: #AE9675;
}
.minLang a:hover::after{
    height: 100%;
}
.contactData p, .contactData a{
    color: #303030;
    font-size: 1.7em;
}
.contactData a, .contactData p:last-child{
    display: inline;
    font-size: 20px;
}
.bottomNav{
    display: flex;
    gap: 4rem;
}
@media screen and (max-width:1100px) {
    .navMax, .dropdown-container{
        display: none;
    }
    .menu.activeMenu{
        transform: translateX(0%);
    }
    .hamburger{
        transform: translateX(0%); 
    }
    .navMin > ul{
        flex-direction: column;
        gap: 30px;
    }
    a{
        padding: 0;
        width: 100%;
        display: inline-block;
    }
    a>p{    
        font-size: 2.5rem;
        margin-left: auto;
        width: fit-content;
        display: block;
        padding: 0;
        position: relative;

    }
    .navMin li{
        transform: translateX(20px);
        opacity: 0;
        transition: 0.3s
    }
    .menu.activeMenu .navMin li{
        opacity: 1;
        transform: translateX(0);
    }     
    .menu.activeMenu .navMin li:nth-child(1){
        transition-delay: 0.75s;
    }
    .menu.activeMenu .navMin li:nth-child(2){
        transition-delay: 0.8s;
    }
    .menu.activeMenu .navMin li:nth-child(3){
        transition-delay: 0.85s;
    }
    .menu.activeMenu .navMin li:nth-child(4){
        transition-delay: 0.9s;
    }
    .menu.activeMenu .navMin li:nth-child(5){
        transition-delay: 0.95s;
    }
    .navMin a>p::after{
        position: absolute;
        bottom: 0; left: 0;
        height: 2px;
        width: 0;
        transition: 0.3s;
        content: " ";
        background-color: #303030;
    }
    .navMin a:hover>p::after{
        width: 100%;
    }
    .contactData a, .contactData p:last-child {
    display: inline;
    font-size: initial;
}
}
@media screen and (max-width:900px){
.bottomNav{
    flex-direction: column-reverse;
    gap: 1rem;
}
@media screen and (max-width: 600px) {
    header{
        padding: 2em;
    }
    h1{
        font-size: 3em;
    }
    a>p{
        font-size: 2em;
    }
    .hamburger{
        width: 74px;
    }
    .minLang{
        flex-direction: column;
    }
    .minLang a{
        text-align: center;
    }
}
}
@media screen and (max-height: 1000px){
    .menu{
        /* background-color: #AE9675; */
    }
    .minLang{
        flex-direction: row;
    }
    .minLang > li {
        flex-grow: 1;
    }
    .minLang a{
        padding: 1rem 0;
        font-size: 1.4rem;
    }
    a>p{
        font-size: 9vw;
    }
}
</style>