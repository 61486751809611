<template>

    <footer ref="contactUs">

        <div class="container">
            <div class="data">
                <div class="kontakData">
                    <h5 class="scrollElement">{{ data.title }}</h5>
                    <p class="scrollElement adress"> {{ data.adress }}
                    </p>
                    <p >tel: <a :href="'tel:' + data.tel">{{ data.tel }}</a></p>
                    <p >e-mail: <a href="mailto:all.made.poczta@gmail.com">all.made.poczta@gmail.com</a></p>
                    <div class="inputHolder">
                        <input type="email" name="" id="" placeholder="Email">
                        <button>&#5171;</button>
                    </div>
                </div>
                <picture v-if="this.windowWidth > 1280">
                <source srcset="@/assets/img/webp/6.webp" type="image/webp">
                <source srcset="@/assets/img/jpeg/6.jpeg" type="image/jpeg">
                <img src="" alt="">
            </picture>
            </div>
            <div class="socialNetworks">
                <ul>
                    <li>
                        <a href="https://www.instagram.com/allmademeble/" target="_blank" name="instagram">
                            <img src="@/assets/img/socialNetworks/inst.svg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/profile.php?id=100082692065266" target="_blank"  name="facebook">
                            <img src="@/assets/img/socialNetworks/fb.svg" alt=""> 
                        </a>
                    </li>
                    <li>
                        <a href="https://t.me/all_made_meble" target="_blank"  name="telegramm">
                            <img src="@/assets/img/socialNetworks/tel.svg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="https://wa.me/48575169238" target="_blank" name="whatsapp">
                            <img src="@/assets/img/socialNetworks/wp.svg" alt="">
                        </a>
                    </li>

                </ul>
            </div>
        </div>

    </footer>

</template>

<script>

export default{
    props:["data", "windowWidth"]
}
</script>

<style scoped>

footer{
padding: 6rem;
}
.container{
    max-width: 1600px;
    margin: 0 auto;
    gap: 5rem;
}
.container, .kontakData{
    display: flex;
    flex-direction: column;
}
.data{
    display: flex;
    gap: 4rem;
    align-items: center;
    justify-content: space-between;
}
.adress{
    white-space: pre-wrap;
}
.kontakData{
    gap: 1rem;
    border-left: 1px solid;
    padding-left: 1.5rem;
    padding-bottom: 2rem;
    height: fit-content;
    max-width: 400px;
}

h5{
    font-size: 2.6rem;
    position: relative;
    margin-bottom: 2rem;
    
}
h5::after{
content: "";
position: absolute;
left: 0;
bottom: -1.5rem;width: 100%;
height: 1px;
background-color: black;
}
p,a{
    font-size: 1.6rem;
    line-height: 2.5rem;
    font-weight: 500;
    color: black;
}
p{
    font-weight: 100;
    color: rgba(0, 0, 0, 0.7);
}
a{
    position: relative;
}
a:hover{
    color: black;
}
.kontakData a::after{
    content: "";
    position: absolute;
    bottom: 0;left: 0;
    width: 0;
    height: 4px;
    background-color:  #AE9675;
    transition: 0.3s;
}
a:hover::after{
    width: 100%;
}
.inputHolder{
    position: relative;
}
.inputHolder>button{
    position: absolute;
    right: 15%;
    top: 50%;
    transform: translate(0, -50%);
    font-size: 3em;
    background-color: transparent;
    opacity: 0.7;
    font-weight: 100;

}
input{
    padding: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.7);
    width: 90%;
    font-size: 1.6em;
}
.data>img{
    max-width: 900px;
    min-width: 400px;
}

ul{
    display:flex; 
}

.socialNetworks{
    display: flex;
justify-content: flex-end;
}
.socialNetworks img{
width: 32px;
}
.socialNetworks a{
    border: 1px solid rgba(0, 0, 0, 0.7);
    display: block;
    padding: 1.5rem 5rem;
    opacity: 0.8;
}

@media screen and (max-width: 1280px) {
    .data {
    gap: 2rem;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column-reverse;
}
.kontakData {
    gap: 1rem;
    border-left: 1px solid;
    max-width: 100%;
}
.socialNetworks a{
    padding: 1.5rem 0;
    width: 100%;
}
.socialNetworks a>img{
     margin: 0 auto;
}
ul{
    width: 100%;
}
li{
    width: 25%;
}
}
@media screen and (max-width: 1000px){
    footer{
        padding: 3em;
    }
}
@media screen and (max-width: 600px){
    ul{
        flex-wrap: wrap;
    }
    li{
        width: 50%;
    }
    .container {
        gap: 3rem;
    }
}
</style>