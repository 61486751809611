<template>

  <Header
  :scrollDirection="this.scrollDirection"
  :data="this.pageData[0]"
  :contactData="this.pageData[8]"
  @lang-change="langChange"
  ></Header>
  <MainVideo 
  :data="this.pageData[1][0]"
  ></MainVideo>
  <TextSection :type=1 
  :data="this.pageData[2]"
  ></TextSection>
  <SectionContainer
  type="list"
  :data="this.pageData[3]"
  ></SectionContainer>
  <TextSection :type=2 
  :data="this.pageData[4]"
  ></TextSection>
  <SectionContainer
  type="faq"
  :data="this.pageData[5]"
  ></SectionContainer>
  <VideoTextScroll></VideoTextScroll>
  <Slider
  :text="this.pageData[9]">
  </Slider>
  <SectionContainer
  type="form"
  :data="this.pageData[7]"

  ></SectionContainer>
  <Footer
  :windowWidth="this.windowWidth"
  :data="this.pageData[8]"
  ></Footer>

</template>

<script>

import Header from "@/components/Header.vue"
import MainVideo from "@/components/MainVideo.vue"
import TextSection from "@/components/TextSection.vue"
import SectionContainer from "@/components/SectionContainer.vue"
import VideoTextScroll from "@/components/VideoTextScroll.vue"
import Footer from "@/components/Footer.vue"
import Slider from "@/components/Slider.vue"



function reveal() {
  let reveals  = document.querySelectorAll(".scrollElement")

  for (var i = 0; i < reveals.length; i++) {
    let windowHeight = window.innerHeight;
    let elementTop = parseInt( reveals[i].getBoundingClientRect().top);

    if (elementTop <parseInt(windowHeight / 3  * 2) ) {
      reveals[i].classList.add("activeScroll");
    } else {
      reveals[i].classList.remove("activeScroll");
    }


  }
}
window.addEventListener("scroll", reveal);
window.addEventListener("load", reveal);




export default {

  data(){
    return{
      primaryColor: '#AE9675',
      primaryColorLight: '#F0F0F0',
      primaryColorDark: '#303030',
      lastScrollTop: 0,
      scrollDirection: true,
      pageLang: document.getElementsByTagName("html")[0].getAttribute("lang"),
      pageData: [],
      windowWidth: window.innerWidth,
    }
  },
  components: { Header, MainVideo, TextSection, SectionContainer, VideoTextScroll, Footer, Slider },  
  created(){
    
    let lang = navigator.language || navigator.userLanguag;
    document.getElementsByTagName("html")[0].getAttribute("lang", lang)
    document.documentElement.lang = lang

    lang = lang.split("-")[0]

      switch(lang){
        case "pl": this.pageLang = "pl";
        break;
        case "ru": case "uk": case "be": this.pageLang = "ru";
        break;
        default: this.pageLang = "en";
        break;
      }

      this.pageData = require("@/assets/json/page-"+this.pageLang +".json")

      this.langChange(lang)


  },
  mounted(){
    window.addEventListener("scroll", this.scrollSirectionM);
    if(this.pageLang == "en"){
      this.pageData = require("@/assets/json/page-en.json")
    }
    window.addEventListener("resize", ()=> {
      this.windowWidth = window.innerWidth
    });

  },
  methods:{
    scrollSirectionM(){
      let st = window.pageYOffset || document.documentElement.scrollTop;
      if(st > this.lastScrollTop)
      this.scrollDirection = false 
      else 
      this.scrollDirection = true
      this.lastScrollTop = st <= 0 ? 0 : st; 
    },
    langChange(langS){
      let lang = document.getElementsByTagName("html")[0].getAttribute("lang")
      if(langS)
        this.pageData = require("@/assets/json/page-"+ langS  +".json")
        else 
        this.pageData = require("@/assets/json/page-"+ lang  +".json")

    }
  }

}
</script>

<style>


:root {
  --primaryColor: #AE9675;
  --primaryColorLight: #F0F0F0;
  --primaryColorDark: #303030;
}

*, *::before, *::after{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  border: none;
  text-decoration: none;
}

body{
  overflow-x: hidden;
  position: relative;
}
html{
  scroll-behavior:smooth;
}

img,video{
  display: block;
  object-fit: cover;
}

div, ul, li, a, section, main, form, input,button, header, footer, body, p{
  font-size: 10px;
}
input:focus, input:active, textarea:focus, textarea:active{
  outline: none;
}
*.lightColorText{
  color: var(--primaryColorLight);
} 
*.darkColorText{
  color: var(--primaryColorDark) !important;
}
*.primaryColorText{
  color: var(--primaryColor);
}

*.lightColorBck{
  background-color: var(--primaryColorLight);
} 
*.darkColorBck{
  background-color: var(--primaryColorDark);
}
*.primaryColorBck{
  background-color: var(--primaryColor);
}

section{
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  background-color: white;
  perspective: 2px;
	-webkit-perspective: 2px;
}

a{
  transition: 0.3s;
}
a:hover{color: var(--primaryColor);}



p.title{
        font-size: 2.5em;
}
h3.title{
    font-size: 6em;
}

#app {
  font-family: 'Prompt', sans-serif;
font-family: 'Roboto', sans-serif;
font-family: 'Space Grotesk', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow: hidden;
}


.scrollElement{
  transition: 0.3s;
  opacity: 0;
}
.scrollElement.LineDown, .scrollElement.LineRight{
  opacity: 1;
}
.scrollElement.LineDown::after{
  opacity: 1;
  height: 0;
  transition: 0.3s;
}

.scrollElement.LineRight::after{
  opacity: 1;
  transition: 0.3s;
  width: 0;
}

.scrollElement.fadeTop{
  transform: translateY(-10%);
}
.scrollElement.fadeLeft{
  transform: translateX(-10%);
}
.scrollElement.activeScroll{
  opacity: 1;
  transform: translate(0);
}
.scrollElement.LineDown.activeScroll::after
{
  height: 100%;
}
.scrollElement.LineRight.activeScroll::after
{
  width: 100%;
}

@media screen and (min-width: 1920px){
  section{
  max-width: 1920px;
}
}
@media screen and (max-width: 600px) {
        h3.title{
            font-size: 4em;
        }
        p.title{
            font-size: 1.8em;
        }
    }
</style>
