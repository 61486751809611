<template>

<section class="" :class="{lightGrey : type === 'form'}" :id="type">
    <div class="titleBlock">
      <h3 class="title scrollElement fadeLeft">{{data.title}}</h3>
      <h3  v-if="type === 'slider'" class="title scrollElement fadeLeft">{{data}}</h3>

      <p v-if="data.stitle" class="title scrollElement fadeLeft">/ {{ data.stitle[0] }} / <b>{{  data.stitle[1]  }}</b> / {{  data.stitle[2]  }} /</p>
    </div>

    <ListUI
     v-if="type === 'list'"
     :data="data.text"
     ></ListUI>
    <ListFAQ v-if="type === 'faq'"
    :data="data.faq"
    ></ListFAQ>
    <ContactForm  v-if="type === 'form'"></ContactForm>
    <Slider v-if="type === 'slider'">

</Slider>
</section>

</template>

<script>

import ListFAQ from '@/components/UI/ListFAQ.vue';
import ListUI from '@/components/UI/ListUI.vue';
import ContactForm from '@/components/ContactForm.vue';
import Slider from '@/components/Slider.vue';


export default{
    data(){
        return{

        }
    },
    props: [ "type", "data"],
    components: {ListUI, ListFAQ, ContactForm, Slider},

}

</script>

<style scoped>
section{
    padding: 6em ;
}
section.lightGrey{
    background-color: #E8E8E8;
}
.titleBlock{
    display: flex;
    align-items: center;
    gap: 2em;
    flex-wrap: wrap;
}
@media screen and (max-width: 1000px){
    section{
    padding: 3em ;
}
}


</style>