<template>

<ul>
    <li v-for="el in data"  class="scrollElement LineDown">
        <h5 class="scrollElement">{{ el.title }}</h5>
        <p class="scrollElement">{{ el.text }}</p>
    </li>
</ul>

</template>

<script>


export default{
    props: ["data"]
}

</script>

<style scoped>
ul{
    display: flex;
    gap: 6em;
    padding: 6em 0;
}
li{
    padding-left: 1.5rem;
    position: relative;
}
li::after{
    content: "";
    position: absolute;
    top: 0;left: 0;
    max-height: 500px;
    width: 1px;
    background-color: black
}
h5{
    font-size: 4em;
    margin-bottom: 3rem;
    text-wrap: wrap;
    position: relative;
}
h5::after{
    content: "";
    width: 100%;
    height: 1px;
    background-color: black;
    position: absolute;
    left: -1.5rem;bottom: -1.5rem;

}
p{
    font-size: 2em;
}
@media screen and (max-width: 1350px){
    ul{
        flex-direction: column;
    }
    h5{
        text-wrap: wrap;
    }
}
@media screen and (max-width: 550px){
    h5{
        font-size: 4em;
    }
    p{
        font-size: 1.8em;
    }
}
@media screen and (max-width: 600px) {
    h5{
    margin-bottom: 1rem;
    font-size: 3em;

}
h5::after{

   bottom: -0.5rem;

}
p{
    font-size: 1.5em;
}
    }
</style>