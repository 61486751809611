<template>

    <section id="home" >
        <ScrollParallax
        :speed="0.7">
            <picture id="paralx">
            <source  media="(min-width:1410px)" srcset="@/assets/img/webp/1.webp" type="image/webp">
            <source media="(min-width:1210px)" srcset="@/assets/img/webp/1-1400.webp" type="image/webp">
            <source media="(min-width:910px)" srcset="@/assets/img/webp/1-1200.webp" type="image/webp">
            <source media="(min-width:710px)" srcset="@/assets/img/webp/1-900.webp" type="image/webp">
            <source media="(min-width:610px)" srcset="@/assets/img/webp/1-700.webp" type="image/webp">
            <source media="(min-width:510px)" srcset="@/assets/img/webp/1-600.webp" type="image/webp">
            <source media="(min-width:410px)" srcset="@/assets/img/webp/1-500.webp" type="image/webp">
            <source media="(max-width:410px)" srcset="@/assets/img/webp/1-400.webp" type="image/webp">
            
            <source media="(min-width:1410px)" srcset="@/assets/img/jpeg/1.jpeg" type="image/jpeg">
            <source media="(min-width:1210px)" srcset="@/assets/img/jpeg/1-1400.jpeg" type="image/jpeg">
            <source media="(min-width:910px)" srcset="@/assets/img/jpeg/1-1200.jpeg" type="image/jpeg">
            <source media="(min-width:710px)" srcset="@/assets/img/jpeg/1-900.jpeg" type="image/jpeg">
            <source media="(min-width:610px)" srcset="@/assets/img/jpeg/1-700.jpeg" type="image/jpeg">
            <source media="(min-width:510px)" srcset="@/assets/img/jpeg/1-600.jpeg" type="image/jpeg">
            <source media="(min-width:410px)" srcset="@/assets/img/jpeg/1-500.jpeg" type="image/jpeg">
            <source media="(max-width:410px)" srcset="@/assets/img/jpeg/1-400.jpeg" type="image/jpeg">
            <img id="parallax" alt="">
        </picture>
        </ScrollParallax>


        <div class="textContent" >
            <h2 class="lightColorText">
                <div class="imgText"><img src="@/assets/logo.svg" alt=""> <p class="primaryColorText">All Made</p></div>
                
                {{ data }}
            </h2>
        </div>
    </section>

</template>

<script>

import ScrollParallax from 'vue3-parallax/src/components/ScrollParallax.vue';


export default{
    components: {
    ScrollParallax
    },
    props:["data"]
}
</script>

<style scoped>
section{
    height: 100vh;
}

section::after{
    content: "";
    top: 0; left: 0;
    position: absolute ;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
}
#parallax{
    width: 100%;
    height: 100vh;

}
.imgText{
    display: flex;
    flex-direction: row;
    gap: 2vw;
    align-items: center;
}
.imgText img{
    max-width: 150px;
    min-width: 65px;
    width: 7.5vw;
}
.textContent{
    position: absolute;
    z-index: 2;
    left: clamp(40px, 4vw, 100px);
     bottom: clamp(40px, 10vh, 500px);
}
h2{
    font-size: clamp(3rem, 4vw, 5rem);

}
h2 p{
    font-size: clamp(5rem, 8vw, 10rem);

}
@media screen and (max-width: 600px) {
    h2{
        font-size: 1.6rem
    }
    h2 p{
        font-size: 4rem;
    }
    .textContent {
    position: absolute;
    z-index: 2;
    left: 30px;
    bottom: clamp(36px, 10vh, 500px);
}

}
</style>