<template>
    <section v-if="type === 1" id="about_us">
    <aside class="darkColorBck large">
        <p class="lightColorText scrollElement fadeTop ">{{ data[2] }} </p>

    </aside>
    <div class="imgBlock primaryColorBck small">
        <picture>
            <source srcset="@/assets/img/webp/3.webp" type="image/webp">
            <source srcset="@/assets/img/jpeg/3.jpeg" type="image/jpeg">
            <img src="" alt="">
        </picture>
        </div>
    <div class="titleImg">
        <div class="titleBlock darkColorText lightColorBck">
            <h3 class="title scrollElement fadeTop">{{ data[0] }}</h3>
            <p class="title scrollElement fadeTop">/ {{ data[1].split(" ")[0] }} / <b>{{  data[1].split(" ")[1] }}</b> / {{  data[1].split(" ")[2] }} /</p>
        </div>
        <div class="imgBlock primaryColorBck large">
            <picture>
                <source srcset="@/assets/img/webp/3.webp" type="image/webp">
                <source srcset="@/assets/img/jpeg/3.jpeg" type="image/jpeg">
                <img src="" alt="">
            </picture>
        </div>


        <aside class="darkColorBck small">
        <p class="lightColorText scrollElement fadeTop">{{ data[2] }}</p>
        </aside>


    </div>
    </section>

    <section v-if="type === 2">

    <div class="titleImg">
        <div class="titleBlock darkColorText primaryColorBck large">
            <h3 class="title scrollElement fadeTop">{{ data[0] }}</h3>
            <p class="title scrollElement fadeTop">/ {{ data[1].split(" ")[0] }} / <b>{{  data[1].split(" ")[1] }}</b> / {{  data[1].split(" ")[2] }} /</p>
        </div>
        <aside class="darkColorBck small">
        <p class="lightColorText scrollElement fadeTop">{{ data[2] }}</p>
    </aside>
        <div class="imgBlock primaryColorBck two">
            <picture>
                <source srcset="@/assets/img/webp/2.webp" type="image/webp">
                <source srcset="@/assets/img/jpeg/2.jpeg" type="image/jpg">
                <img src="" alt="">
            </picture>
        </div>
    </div>
    <aside class="darkColorBck large">
        <p class="lightColorText scrollElement fadeTop">{{ data[2] }}</p>
    </aside>
    <div class="titleBlock darkColorText primaryColorBck small">
            <h3 class="title scrollElement fadeTop">{{ data[0] }}</h3>
            <p class="title scrollElement fadeTop">/ {{ data[1].split(" ")[0] }} / <b>{{  data[1].split(" ")[1] }}</b> / {{  data[1].split(" ")[2] }} /</p>
        </div>
    </section>  

</template>

<script>

export default{
    data(){
        return{
            
        }
    },
    props: ["type", "data", "title"]
}
</script>

<style scoped>
    section{
        display: flex;
    }
    aside{
        max-width: 40%;
        padding: 4em 6em;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 420px;
    }
    aside p{
        font-size: 3em;
    }
    .titleImg{
        flex-grow: 2;
        height: 100%;
    }
    .titleBlock{
        padding: 2em 4em;
        text-align: center;
    }
    .imgBlock{
        padding: 6em ;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .imgBlock img{
        width: 100%;
    }
    .small{
        display: none;
    }
    picture{
        width: 100%;
    }
    @media screen and (max-width: 1200px) {
    aside p{
        font-size: 2.5em;
    }
    .imgBlock{
        padding: 3em;
    }
    p.title {
        font-size: 2.2em;
    }
    h3.title{
        font-size: 5em;
    }
    }
    @media screen and (max-width: 1400px) {
        section{
            flex-direction: column-reverse;
        }
        aside p{
            font-size: 2.2em;
        }
        .large{
            display: none;
        }
        .small{
            display: flex;
        }
        aside{
            width: 100%;
            max-width: 100%;
        }
        .imgBlock{
            padding: 4em;
        }
        .imgBlock img{
            height: 300px;
        }
        .titleBlock.small{
            display: block;
        }
        .imgBlock.primaryColorBck.two{
            background-color: #303030;
        }
    }
    @media screen and (max-width: 600px) {
        h3.title{
            font-size: 4em;
        }
        p.title{
            font-size: 1.8em;
        }

aside p{
    font-size: 1.8em;
    }
    }
    @media screen and (max-width: 550px){
        aside {
            padding: 3em;
        }
        .imgBlock{
            padding: 2em;
        }
    }
</style>