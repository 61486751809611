<template>
    <section id="works">
      <h2>{{ text.title }}</h2>
      <carousel  :items-to-show="1"
  :itemsToScroll="1"
  :spaceBetween="0"
    :navigation="true"
    :wrapAround="true"
    :mouseDrag="false"
    :touchDrag="false"
  >
    <slide :key="1">
        <ImgComparisonSlider>
            <img slot="first" src="@/assets/img/jpeg/Slider/1.jpg"/>
            <img slot="second" src="@/assets/img/jpeg/Slider/1-1.jpg"/>
        </ImgComparisonSlider>
    </slide>
    <slide :key="2">
        <ImgComparisonSlider>
            <img slot="first"  src="@/assets/img/jpeg/Slider/2.jpg"/>
            <img slot="second" src="@/assets/img/jpeg/Slider/2-1.jpg"/>
        </ImgComparisonSlider>
    </slide> 
    <slide :key="3">
          <img src="@/assets/img/jpeg/Slider/3.jpg"/>
    </slide> 
    <template #addons>
      <navigation />
      <pagination />
    </template>
  </carousel > 
    </section>


</template>

<script>

import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import { ImgComparisonSlider   } from '@img-comparison-slider/vue';

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation ,
    ImgComparisonSlider ,
  },
  props:["text"]
}


</script>

<style scoped>
*{
 outline: none; 
}
img{
    width: 100%;
    height: 100%;
}
section{
  background-color: white;
}

.rendered{
  height: 100%;
  display: flex;
}
.carousel__item {
  min-height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 30px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}




h2{
        font-size: 5em;
        padding-left: 2rem ;
        padding-top: 2rem ;
    }
    @media screen and (max-width: 600px) {
      h2{
            font-size: 4em;
        }
    }


</style>
<style>
.carousel{
  width: 80%;
}
.carousel__icon {
    width: 5rem;
    height: 5rem;
    fill: #ae9675;
}
.carousel__next {
  width: 5vw;
right: -5%;}
.carousel__prev{
  width: 5vw;
left: -5%;
}

@media screen and (max-width: 900px) {
  .carousel{
  width:100%;
}
.carousel__next {
  width: 10vw;
  min-width: 40px;
right: 0;
}
.carousel__prev{
  width: 10vw;
  min-width: 40px;

left: 0;
}

    }
</style>