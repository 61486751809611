<template>
    <section id="videoScroll">
            <video autoplay loop muted playsinline preload="auto">
                <source media="(min-width:1410px)" src="@/assets/video/webm/16-9-F-HD.webm" type="video/webm">
                <source media="(min-width:1410px)" src="@/assets/video/mp4/16-9-F-HD.mp4" type="video/mp4">

                <source media="(min-width:1210px)" src="@/assets/video/webm/4-3-F-HD.webm" type="video/webm">
                <source media="(min-width:1210px)" src="@/assets/video/mp4/4-3-F-HD.mp4" type="video/mp4">

                <source media="(min-width:700px)" src="@/assets/video/webm/9-16-F-HD.webm" type="video/webm">
                <source media="(min-width:700px)" src="@/assets/video/mp4/9-16-F-HD.mp4" type="video/mp4">
                <source media="(max-width:700px)" src="@/assets/video/webm/9-16-F-HD.webm" type="video/webm">
                <source media="(max-width:700px)" src="@/assets/video/mp4/9-16-F-HD.mp4" type="video/mp4">
            </video>
    </section>
</template>

<style scoped>
section{
    height: 100vh;
    position: relative;
}
video,img{
    width: 100%;
    height: 100vh;
}
</style>