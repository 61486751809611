<template>

    <form action=""  @submit.prevent="sendEmail">

        <div class="formLine">
            <div class="inputLabel">
                <label  for="email">E-MAIL</label>
                <input type="email" v-model="email" placeholder="info@gmail.com" id="email" name="email">
            </div>
            <div class="inputLabel">
                <label for="name">NAME</label>
                <input v-model="name" type="text" placeholder="Name" id="name" name="name">
            </div>
        </div>
        <div class="formLine">
            <div class="textAreaLabel">
                <label for="textArea">MESSAGE</label>
                <textarea    v-model="message"  id="textArea" name="message" placeholder="Type here"></textarea>
            </div>
        </div>
        <div class="formLine">
            <!-- <div class="inputLabel">
                <label for="file">UPLOAD FILE (s)</label>
                <input type="file" placeholder="File(s)" id="file" name="file" multiple>
            </div> -->
            <div class="darkColorBck button">
                    <input class=" button darkColorBck primaryColorText" type="submit" value="Send">
            </div>
        </div>


    </form>

</template>

<script>

import emailjs from 'emailjs-com';

export default{

    data() {
    return {
      name: '',
      email: '',
      message: ''
    }
  },
  methods: {
    sendEmail(e) {
   
        if (this.name == null || this.email == null || this.message == null){
            try {
                emailjs.sendForm('service_ybj13dk', 'template_ts0kmoq', e.target,  'F00-hxZ7oRLxFcOkK', {
        
                  name: this.name,
                  email: this.email,
                  message: this.message
            })
            alert("The e-mail was sent")
            } catch(error) {
                console.log({error})
            }
        } else {
            alert("Provide all the data")
        }
      this.name = ''
      this.email = ''
      this.message = ''
    },
  }


}



</script>

<style scoped> 
form{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    margin: 0 auto;
    margin: 5rem 0;
    max-width: 1600px;
}

label{
    font-size: 2rem
}
input, textarea, button{
    font-size: 1.6rem;
    padding: 1rem ;
    border: 1px solid;
}
input, textarea{
    background-color: transparent;
}
.formLine{
    display: flex;
    width: 100%;
    gap: 3rem;
}
.inputLabel,.textAreaLabel, .button{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: flex-end;
}
 .button{
    width: 100%;
    display: flex;
    align-items: center;
    transition: 0.3s;
    cursor: pointer;
 }
 .button:hover{
    background-color: #AE9675;
    color: #303030;
 }


.textAreaLabel{
    width: 100%;
}

button{
    cursor: pointer;
}

textarea{
    height: 300px;
    resize: none;

}
@media screen and (max-width: 1000px){
    form{
    margin: 2.5rem 0;
}
}

@media screen and (max-width: 900px) {
    .formLine{

    gap: 1rem;
    } 
    label{
    font-size: 1.4rem
    }
    textarea{
        height: 200px;
    }
}
@media screen and (max-width: 700px) {
    .formLine{
    flex-direction: column;
    } 
    .inputLabel, .button{
        width: 100%;
    }
}
@media screen and (max-width: 500px) {
    .formLine, .inputLabel, .textAreaLabel{
    gap: 0.5rem;
    } 
    input, textarea, button, label{
    font-size: 1.4rem;
    }
    .inputLabel, .button{
        width: 100%;
    }
    .button{
        margin-top: 0.5rem;
    }
    textarea{
        height: 150px;
    }
}
</style>