<template>

    <div class="container" @click="click($event)">
        <div class="titleFaq">
            <h5 class="scrollElement LineRight">{{ title }}</h5>
            <span>{{ symbol }}</span>
        </div>

        <p id="text" :class="{ active : active}">{{ text }}</p>
    </div>

</template>

<script>

export default{
    data(){
        return{
            active: false,
            symbol: "+"
        }
    },
     props:['title', 'text', 'index'],

     methods: {
        click(event){
            this.active =! this.active;
            this.symbol = this.active ? "-" : "+"
            let el = document.querySelectorAll("#text")[this.index - 1]
            if(this.active)
                el.style.maxHeight ="400px"
             else 
                el.style.maxHeight = 0
        }
     }
}
</script>

<style scoped>
.container{
    cursor: pointer;
}

h5{
    font-size: 4em;
    padding-bottom: 1rem;
    position: relative;
}
h5::after{
    content: "";
    position: absolute;
    bottom: -0.5rem;left: 0;
    max-width: 1800px;
    height: 1px;
    width: 100%;
    background-color: black
}
.titleFaq{
    position: relative;

}
span{
    font-weight: 100;
    position: absolute;
    top: 50%;right: 0;
    opacity: 0.8;
    transform: translate(-1rem, -50%);
    font-size: 4em;

}
p{
    font-size: 1.8rem;
    margin-top: 1.5rem;
    transition: max-height 0.4s ease-out;
    overflow: hidden;
    max-height: 0;
    white-space: pre-wrap;

}
@media screen and (max-width: 1100px){
    h5{
        font-size: 3.5em;
    }
    p{
        font-size: 1.6rem;
    }
}
@media screen and (max-width: 550px){
    h5{
        font-size: 3em;
    }
    p{
        font-size: 1.4rem;
    }
}
</style>